.carousel {
    width: 100vw;
    height: 100vh;
    position: relative;
  }
  
  .carousel > ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: 1000ms opacity ease-in-out;
    transition-delay: 1000ms;
  }
  
  .slide > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .slide-active {
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
  }